 @font-face {
    font-family: DMSans-Bold;
    src: url('../fonts/DMSans-Bold.ttf');
}
@font-face {
    font-family: Manrope-Regular;
    src: url('../fonts/Manrope-Regular.ttf');
}
@font-face {
    font-family: Manrope-Light;
    src: url('../fonts/Manrope-Light.ttf');
}
@font-face {
    font-family: Manrope-Bold;
    src: url('../fonts/Manrope-Bold.ttf');
}
@font-face {
    font-family: Manrope-SemiBold;
    src: url('../fonts/Manrope-SemiBold.ttf');
}
@font-face {
    font-family: Inter-SemiBold;
    src: url('../fonts/Inter-SemiBold.ttf');
}
@font-face {
    font-family: Inter-Bold;
    src: url('../fonts/Inter-Bold.ttf');
}
@font-face {
    font-family: Inter-Light;
    src: url('../fonts/Inter-Light.ttf');
}
@font-face {
    font-family: Inter-Regular;
    src: url('../fonts/Inter-Regular.ttf');
}
@font-face {
    font-family: Inter-Medium;
    src: url('../fonts/Inter-Medium.ttf');
}


@font-face {
    font-family: Roboto-Medium;
    src: url('../fonts/Roboto-Medium.ttf');
}
@font-face {
    font-family: Roboto-Bold;
    src: url('../fonts/Roboto-Bold.ttf');
}

